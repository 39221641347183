.Snackbar {
  position: fixed;
  z-index: 200;
  border: 0px solid #eee;
  border-radius: .25rem;
  box-shadow: 0 2px 2px #ccc;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  bottom: 25px;
  left: 25%;
  width: 50%;
  transition: all 0.3s ease-out;
  color: white;
}

.SnackbarOpen {
  opacity: 1;
  transform: translateY(0);
}

.SnackbarClosed {
  opacity: 0;
  transform: translateY(-100%);
}

.error {
  background-color: red;
}

.warning {
  background-color: orange;
}

.success {
  background-color: green;
}